<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 编辑产品编号</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="140px">
                <div class="form_box" style="display: block;">
                    <el-form-item label="品名:">
                        <div>{{ info.product_name || '' }}</div>
                    </el-form-item>
					
					<el-form-item label="产品编号:">
					    <div>{{ info.product_no || '' }}</div>
					</el-form-item>
                    <el-form-item label="原产品编号前缀:">
						<el-select v-model="form.old_product_no" placeholder="请选择" style="width:100%" allow-create filterable>
						    <el-option :label="item.value" v-for="(item, index) in oldPrefixList" :key="index"
						        :value="item.value"></el-option>
						</el-select>
                    </el-form-item>
                    <el-form-item label="新产品编号前缀:" prop='product_no'>
                        <el-select v-model="form.product_no" placeholder="请选择" style="width:100%" filterable>
                            <el-option :label="item.value" v-for="(item, index) in sysConfig" :key="index"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm()" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>
</template>

<script>
export default {
    name: "addList",
    data() {
        return {
            id: '',
            rules: {
                product_no: [
                    { required: true, message: '请选择新产品编号前缀', trigger: 'change' }
                ],
            },
            form: {
                old_product_no: '',
                product_no: ''
            },
            sysConfig: [],
			oldPrefixList: [],
            info: {}
        };
    },
    created() {
        this.id = this.$route.query.id || '';
        this.getSysConfigindex();
    },
    methods: {
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let data = {
                        id: this.id,
                        old_product_no: this.form.old_product_no,
                        product_no: this.form.product_no
                    }
                    this.$httpPost("/backend/product.Product/editProductNo", data).then((res) => {
                        if (res.status == 200) {
                            this.$message.success('操作成功');
                            setTimeout(() => {
                                this.$router.go(-1)
                            }, 1500);
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch((err) => {
                    });
                } else {
                    return false;
                }
            });
        },
        getProductInfo() {
            return new Promise((resolve, reject) => {
                let data = {
                    id: this.id
                }
                this.$httpGet("/backend/product.Product/editProductNo", data).then((res) => {
                    if (res.status == 200) {
                        this.info = res.data
                        resolve('success')
                    } else {
                        this.$message.error(res.message);
                    }

                }).catch((err) => {
                });
            })

        },
        // 获取产品前缀
        getSysConfigindex() {
            let that = this
            this.$httpGet("/backend/SysConfig/index", { size: 10000000, groups: '产品编号前缀' }).then((res) => {
                if (res.status == 200) {
                    this.sysConfig = res.data.data
                    this.getProductInfo().then(res => {
                        if (this.sysConfig.length > 0) {
                            this.sysConfig.map(item => {
                                if (this.info.product_no.indexOf(item.value) != -1) {
									if (that.form.old_product_no == '') {
										that.form.old_product_no = item.value
									}
                                    that.oldPrefixList.push(item)
                                }
                            })
                        }
                    })
                } else {
                    this.$message.error(res.message);
                }

            }).catch((err) => {
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// .box_input {
//   div {
//     &:first-child {
//       img {
//         display: none;
//       }
//     }
//   }
// }
::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
}

::v-deep .el-upload--picture-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
}
</style>
